import React from 'react';
// eslint-disable-next-line import/no-named-as-default
import {provider, useInstance} from 'react-ioc';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {ApolloClient, ApolloProvider} from '@apollo/client';
import {ConfigProvider, Skeleton} from 'antd';
import ApolloClientDI from 'graphql/ApolloClientDI';
import {observer} from 'mobx-react-lite';
import {Home} from 'pages/Home/Home';
import {HOME, LOGIN} from 'routes/routes';
import {Store} from 'store/Store';
import StoreDI from 'store/StoreDI';

import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

import {Layout} from '../layout/Layout/Layout';
import {Login} from '../pages/Login/Login';

import './App.css';

function App() {

    const store = useInstance(Store);
    const {hydrated, auth} = store;
    const {hasAuthToken} = auth;

    const apolloClient = useInstance(ApolloClient);

    if (!hydrated) {
        return (
            <Skeleton active={true}/>
        );
    }

    return (
        <ApolloProvider client={apolloClient}>

            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#E03F96',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 16,
                    },
                }}>
                <BrowserRouter>
                    <ScrollToTop/>
                    <Layout>

                    {!hasAuthToken && (
                        <Routes>
                            <Route path={LOGIN} element={<Login/>}/>
                            <Route path="*" element={<Navigate to={LOGIN}/>}/>
                        </Routes>
                    )}

                    {hasAuthToken && (
                        <Routes>
                            <Route path={HOME} element={<Home/>}/>
                            <Route path="*" element={<Navigate to={HOME}/>}/>
                        </Routes>
                    )}

                    </Layout>

                </BrowserRouter>
            </ConfigProvider>
        </ApolloProvider>
    );
}

export default provider(
    StoreDI(),
    ApolloClientDI()
)(observer(App));
