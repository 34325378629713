import {gql} from '@apollo/client';

export const UPDATE_DEVICE = gql`
    mutation updateDevice($data: DeviceInput!, $id: ID!) {
        updateDevice(id: $id, data: $data) {
            data {
                id
                attributes {
                    UID
                    tempBath
                    tempLiving
                    humidityLiving
                    heatingLiving
                    heatingBath
                    setTemp
                    updatedAt
                }
            }
        }
    }
`;