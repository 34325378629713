import React from 'react';
import {MinusOutlined, PlusOutlined, WarningOutlined} from '@ant-design/icons';
import {Line} from '@ant-design/plots';
import {useMutation, useQuery} from '@apollo/client';
import {Badge, Button, Skeleton} from 'antd';
import dayjs from 'dayjs';
import {devices, devicesVariables} from 'graphql/__generated__/devices';
import {DEVICES} from 'graphql/device';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';

import {getDeviceUID} from '../../env';
import {updateDevice, updateDeviceVariables} from '../../graphql/__generated__/updateDevice';
import {UPDATE_DEVICE} from '../../graphql/updateDevice';

import icon_bath from './icon-bath.svg'
import icon_heat from './icon-heat.svg'
import icon_heating from './icon-heating.svg'
import icon_living from './icon-living.svg'

import cls from './Home.module.scss'

// interface IDeviceData {
//     setTemp: number
//     tempLiving: number
//     humidityLiving: number
//     tempBath: number
//     heatingLiving: boolean
//     heatingBath: boolean
// }

interface IGraph {
    date: string
    value: number
    category: string
}

export const Home = observer(() => {

    const query = useQuery<devices, devicesVariables>(DEVICES, {
        variables: {
            filters: {
                UID: {eq: getDeviceUID()}
            },
            pagination: {
                limit: 1
            },
            measuringsFilters: {
                createdAt: {between: [dayjs().subtract(2, 'hour').format(), dayjs().format()]}
            },
            measuringsPagination: {
                limit: (60 / 2) * 2,
            },
            measuringsSort: ['createdAt:ASC'],
        },
        pollInterval: 1000,
    })

    const [update] = useMutation<updateDevice, updateDeviceVariables>(UPDATE_DEVICE)

    const device = query.data?.devices?.data[0].attributes
    const averageTemp = Math.round(((device?.tempLiving! + device?.tempBath!) / 2) * 10) / 10

    const online = dayjs().diff(dayjs(device?.updatedAt), 'minutes') < 3

    if (query.loading) {
        return <Skeleton active={true}/>
    }

    const onSetTemp = async (temp: number) => {
        await update({
            variables: {
                id: query.data?.devices?.data[0].id!,
                data: {
                    setTemp: temp
                }
            },
            onCompleted: () => {
                query.refetch()
            }
        })
    }

    let graph: IGraph[] = []

    _.map(device?.measurings?.data, (item) => (
        graph.push({
            date: dayjs(item.attributes?.createdAt!).format('YYYY-MM-DD HH:mm'),
            value: +item.attributes?.data.tempBath,
            category: 'bath'
        })))
    _.map(device?.measurings?.data, (item) => (
        graph.push({
            date: dayjs(item.attributes?.createdAt!).format('YYYY-MM-DD HH:mm'),
            value: +item.attributes?.data.tempLiving,
            category: 'living'
        })))

    console.log('graph', graph)


    const config = {
        data: graph,
        height: 120,
        shapeField: 'smooth',
        xField: 'date',
        yField: 'value',
        colorField: 'category',
        seriesField: 'category',
        legend: null,
        axis: null,
        style: {
            lineWidth: 2,
        },
    };

    return (
        <div className={cls.home}>

            <div className={cls.content}>
                <div className={cls.status}>
                    {online
                        ?
                        <span className={cls.success}><Badge status={'success'}/> Онлайн</span>
                        :
                        <span className={cls.warning}><WarningOutlined/> Нет связи</span>
                    }
                </div>
                <div className={cls.title}>{averageTemp}°C</div>
                <div className={cls.caption}>Влажность {device?.humidityLiving}%</div>

                <div className={cls.rooms}>
                    <div className={cls.room}>
                        <img src={icon_living} alt={'icon'} className={cls.icon}/>
                        <div>{device?.tempLiving!} °C</div>
                        <img src={device?.heatingLiving ? icon_heat : icon_heating} alt={'icon'} className={cls.icon}/>
                    </div>
                    <div className={cls.room}>
                        <img src={icon_bath} alt={'icon'} className={cls.icon}/>
                        <div>{device?.tempBath!} °C</div>
                        <img src={device?.heatingBath ? icon_heat : icon_heating} alt={'icon'} className={cls.icon}/>
                    </div>
                </div>

                <div className={cls.graph}>

                    {/* @ts-ignore */}
                    <Line {...config} />
                </div>

            </div>

            <div className={cls.controls}>
                <Button onClick={() => onSetTemp(13)} shape={'round'} size={'large'} className={cls.button}>13
                    °C</Button>
                <Button onClick={() => onSetTemp((device?.setTemp! - 1))} shape={'circle'} size={'large'}
                        icon={<MinusOutlined/>}/>
                <span className={cls.current}>{device?.setTemp} °C</span>
                <Button onClick={() => onSetTemp((device?.setTemp! + 1))} shape={'circle'} size={'large'}
                        icon={<PlusOutlined/>}/>
                <Button onClick={() => onSetTemp(23)} shape={'round'} size={'large'} className={cls.button}>23
                    °C</Button>
            </div>


        </div>
    )
})