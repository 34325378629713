import {gql} from '@apollo/client';

export const DEVICES = gql`
    query devices($filters: DeviceFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = [], $measuringsFilters: MeasuringFiltersInput, $measuringsPagination: PaginationArg = {}, $measuringsSort: [String] = []) {
        devices(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    UID
                    tempBath
                    tempLiving
                    humidityLiving
                    heatingLiving
                    heatingBath
                    setTemp
                    updatedAt
                    measurings (filters: $measuringsFilters, pagination: $measuringsPagination, sort: $measuringsSort) {
                        data {
                            id
                            attributes {
                                createdAt
                                data
                            }
                        }
                    }
                }
            }
        }
    }
`;