import {useState} from 'react';
import {useInstance} from 'react-ioc';
import {useMutation} from '@apollo/client';
import {Alert, Button, Form, Input} from 'antd';
import {LOGIN} from 'graphql/login';
import {observer} from 'mobx-react-lite';

import {login, loginVariables} from '../../graphql/__generated__/login';
import {Store} from '../../store/Store';

interface ILoginData {
    identifier: string
    password: string
}

export const Login = observer(() => {

    const [invalidPair, setInvalidPair] = useState(false)

    const [login] = useMutation<login, loginVariables>(LOGIN)

    const store = useInstance(Store);


    const onFinish = async (data: ILoginData) => {
        await login({
            variables: {
                input: {
                    identifier: data.identifier,
                    password: data.password,
                    provider: 'local',
                }
            },
            onCompleted: (data) => {
                store.auth.setToken(data.login.jwt!)
            },
            onError: (error) => {
                if (error.message === 'Invalid identifier or password') {
                    setInvalidPair(true)
                }
            }
        })
    }

    return (
        <div>

            <h1>Вход</h1>

            <Form layout={'vertical'} onFinish={onFinish}>

                {invalidPair && (
                    <Form.Item>
                        <Alert message={'Не верный логин и/или пароль'} type={'error'}/>
                    </Form.Item>
                )}

                <Form.Item name={'identifier'} label={'Логин'}
                           rules={[
                               {required: true, message: 'Заполните поле'},
                               {type: 'email', message: 'Не корректный формат'},
                           ]}

                >
                    <Input/>
                </Form.Item>

                <Form.Item name={'password'} label={'Пароль'}
                           rules={[
                               {required: true, message: 'Заполните поле'},
                           ]}

                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item>
                    <Button htmlType={'submit'} type={'primary'}>Войти</Button>
                </Form.Item>

            </Form>
        </div>
    )
})